table.searchResult {
  border-collapse: collapse;
  width: 100%; }

table.searchResult td, table.searchResult th {
  border: 1px solid #ddd;
  padding: 8px; }

table.searchResult tr:nth-child(even) {
  background-color: #f2f2f2; }

table.searchResult tr:hover {
  background-color: #ddd;
  cursor: pointer; }

table.searchResult th {
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: left;
  background-color: #1d396e;
  color: white; }
