.navbar-brand .logo {
  width: 70%; }

/*.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}*/
.navbar-toggler, .navbar-toggler:focus, .navbar-toggler:active {
  border-color: transparent !important;
  outline: none;
  box-shadow: none; }

.animated-hamburger {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }

.animated-hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

.animated-hamburger span {
  background: #e3f2fd; }

.animated-hamburger span:nth-child(1) {
  top: 0px; }

.animated-hamburger span:nth-child(2), .animated-hamburger span:nth-child(3) {
  top: 10px; }

.animated-hamburger span:nth-child(4) {
  top: 20px; }

.animated-hamburger.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%; }

.animated-hamburger.open span:nth-child(2) {
  transform: rotate(45deg); }

.animated-hamburger.open span:nth-child(3) {
  transform: rotate(-45deg); }

.animated-hamburger.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%; }
